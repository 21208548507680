import React, { FC, useMemo, useState } from 'react'
import { BookingType } from '../../../../../../../stores/ADMarketTypes.types'
import { Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap'
import useCampaignData from '../../../../../../../stores/campaignData'
import DetailItem from '../../../../../../../utils/DetailItem'
import { moneyToString } from '../../../../../../../utils/money-formatter'
import s from '../../../../../campaigns-list/CampaignList.module.scss'
import { motion } from 'framer-motion'
import useResponsive from '../../../../../../../utils/useResponsive'
import IntlFormatter from '../../../../../../../utils/intl-money-formatter'
import ls from './bookingsCart.module.scss'

interface IBookingsCart {
	selectedBookings: BookingType[]
	handleRemoveAllPlacements: () => void
	handleRemovePlacement: (id: number) => void
	handleConfirm: () => void
	isMobile?: boolean
}

const BookingsCart: FC<IBookingsCart> = ({
	selectedBookings,
	handleRemoveAllPlacements,
	handleConfirm,
	handleRemovePlacement,
	isMobile = false,
}) => {
	const [, campaignInterface] = useCampaignData()
	const [disabled, setDisabled] = useState(false)

	const totalPrice = useMemo<number>(() => {
		return selectedBookings.reduce((acc, el) => {
			return acc + el.placement_showing_count.ac_price
		}, 0)
	}, [selectedBookings])

	const isEmpty = selectedBookings.length === 0
	return (
		<div
			className={'d-flex flex-column w-100 h-100'}
			style={{ zIndex: isMobile ? 10000 : 2 }}
		>
			<div
				className={'d-flex flex-column'}
				style={{
					flex: '1 1 auto',
					overflowY: 'auto',
					overflowX: 'hidden',
				}}
			>
				{isEmpty ? (
					<CartEmpty />
				) : (
					selectedBookings.map((booking) => (
						<React.Fragment key={booking.id}>
							<Booking
								booking={booking}
								key={booking.id}
								handleRemoveBooking={handleRemovePlacement}
							/>
							<Dropdown.Divider className={'m-0 p-0'} />
						</React.Fragment>
					))
				)}
			</div>
			<div className={'p-4 w-100'}>
				{!isEmpty && (
					<>
						<Button
							variant={'danger'}
							onClick={handleRemoveAllPlacements}
							className={'w-100 mb-3'}
							disabled={disabled}
						>
							Удалить все места
						</Button>
						<Dropdown.Divider className={'m-0 p-0 mb-2'} />
						{/* <DetailItem
							left={'Срок размещения'}
							right={campaignInterface.getCampaignPeriod()}
							bottomDivider={false}
						/> */}
						<div className={'d-flex justify-content-between mb-3'}>
							<h5>Итого:</h5>
							<h5>{IntlFormatter.format(totalPrice, true)}</h5>
						</div>
					</>
				)}
				<Button
					onClick={async () => {
						setDisabled(true)
						await handleConfirm()
						setDisabled(false)
					}}
					className={'w-100'}
					disabled={disabled}
				>
					{isEmpty ? 'Сохранить изменения' : 'Подтвердить выбор'}
				</Button>
			</div>
		</div>
	)
}

export default BookingsCart

const Booking = ({
	booking,
	handleRemoveBooking,
}: {
	booking: BookingType
	handleRemoveBooking: (id) => void
}) => {
	const { isMobile } = useResponsive()
	const [dragProgress, setDragProgress] = useState(0)
	const dragMinimum = 220
	const onDrag = (event, info) => {
		const { offset } = info
		if (offset.x > 0) {
			setDragProgress(0)
			return event.preventDefault()
		}
		const progress = Math.abs((offset.x / dragMinimum) * 100)
		setDragProgress(progress > 100 ? 100 : progress)
	}
	const onDragEnd = (event, info) => {
		if (dragProgress < 100) {
			setDragProgress(0)
		} else {
			handleRemoveBooking(booking.id)
		}
	}
	return (
		<motion.div
			className={'d-flex flex-column position-relative'}
			style={{ gap: '4px', padding: '16px 24px 8px 24px' }}
			drag={isMobile ? 'x' : undefined}
			dragDirectionLock
			whileDrag={{ scale: 0.9 }}
			dragConstraints={{ top: 0, right: 0, bottom: 0, left: -5 }}
			dragElastic={{ right: 0, left: 0.3 }}
			dragMomentum={false}
			onDrag={onDrag}
			onDragEnd={onDragEnd}
		>
			{isMobile && (
				<div
					className={'h-100 position-absolute top-0 left-0 '}
					style={{
						width: '100%',
						right: '-101%',
						display: 'flex',
						justifyContent: 'flex-start',
						// paddingLeft: '10px',
						alignItems: 'center',
						fontSize: '12px',
						color: dragProgress === 100 ? '#DC3545' : '#6C757D',
					}}
				>
					<motion.span
						style={{
							transform: `scale(${
								(1 + dragProgress / 100) * 0.9
							})`,
						}}
					>
						Удалить
					</motion.span>
				</div>
			)}
			<div></div>
			<span
				className={'text-secondary'}
				style={{ fontSize: '14px', paddingRight: '40px' }}
			>
				{booking.placement.placement_type.name}
			</span>
			<div style={{ paddingRight: '40px' }}>{booking.placement.name}</div>
			<div className={ls.workMode}>
				<span>
					{booking.placement.only_work_days
						? 'Только рабочие дни'
						: 'Ежедневно'}
				</span>
				{booking.placement.door_type === 'indoor' && (
					<p>{`На ${booking.placement_showing_count.video_duration} секунд`}</p>
				)}
			</div>

			<DetailItem
				left={`${
					booking.placement_showing_count.ac_days_count
				} x ${moneyToString(booking.placement_showing_count.price)} ₽`}
				right={`${moneyToString(
					booking.placement_showing_count.ac_price
				)} ₽`}
				bottomDivider={false}
				leftSecondary={false}
				fontSize={'16px'}
				padding={1}
			/>
			{/*Delete Icon*/}
			<OverlayTrigger
				placement={'left'}
				overlay={<Tooltip id={`tooltip-top`}>Удалить место</Tooltip>}
			>
				<div
					className={s.trash}
					style={{ position: 'absolute', top: 16, right: 16 }}
					onClick={() => {
						handleRemoveBooking(booking.id)
					}}
				>
					<i className={'bi bi-trash'} />{' '}
				</div>
			</OverlayTrigger>
		</motion.div>
	)
}

const CartEmpty = () => (
	<div className={'p-4'}>
		<div className={'w-100 d-flex justify-content-center'}>
			<i
				className={'bi bi-info-circle text-secondary text-center'}
				style={{ fontSize: '40px' }}
			/>{' '}
		</div>
		<h5 className={'text-secondary text-center'}>Нет добавленных мест</h5>
	</div>
)
