import React from 'react'
import { Modal } from 'react-bootstrap'
import YandexMap, { YmapProps } from './YandexMap'
import ProviderYandexMap, { IProviderYandexMap } from './ProviderYandexMap'
import ProviderYandexMapNew from '../_provider/Bookings/content/details/ProviderYandexMapNew'

interface IModalYandexMap {
	modalTitle?: string
	show: boolean
	onHide: () => void
	yMapProps: YmapProps | IProviderYandexMap
	fullScreen?:
		| true
		| 'sm-down'
		| 'md-down'
		| 'lg-down'
		| 'xl-down'
		| 'xxl-down'
		| undefined
	isMobile?: boolean
	providerMap?: boolean
	placements?: any
}
const ModalYandexMap: React.FC<IModalYandexMap> = ({
	modalTitle,
	show,
	onHide,
	yMapProps,
	fullScreen = undefined,
	isMobile = false,
	providerMap = false,
	children,
}) => {
	return (
		<Modal
			show={show}
			onHide={onHide}
			fullscreen={fullScreen}
			animation={false}
		>
			{modalTitle && (
				<Modal.Header closeButton>
					<Modal.Title
						as={'h5'}
						style={{ fontSize: isMobile ? '12px' : '' }}
					>
						{modalTitle}
					</Modal.Title>
				</Modal.Header>
			)}
			<Modal.Body style={{ padding: 0, height: 'calc(100vh - 63px)' }}>
				{providerMap ? (
					<ProviderYandexMap {...yMapProps} />
				) : (
					<YandexMap {...yMapProps} />
				)}

				{children}
			</Modal.Body>
		</Modal>
	)
}

export default ModalYandexMap
