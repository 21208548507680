import React from 'react'
import { FormatsSelector } from './formats-selector/FormatsSelector'
import { TotalCampaignBudget } from './total-budget/TotalCampaignBudget'
import { AdvFormatsNav } from './adv-formats-navigation/AdvFormatsNav'
import { AdvFormats } from '../../types'

type Props = {
	onSelectFormat: (format: AdvFormats) => void
	onBack: () => void
	onNextStep: () => void
}

export const AdvFormatSelectionStage = ({
	onSelectFormat,
	onBack,
	onNextStep,
}: Props) => {
	return (
		<>
			<FormatsSelector onSelectFormat={onSelectFormat} />

			<TotalCampaignBudget />

			<AdvFormatsNav onBack={onBack} onNextStep={onNextStep} />
		</>
	)
}
