import React, { useEffect, useState } from 'react'
import { OutdoorMassCoverage } from 'src/components/_client/create-campaign/Step2-ADformats/outdoor-ad/components'
import { CardWrapper } from '../../../../../common/card-wrapper/CardWrapper'
import { authFetch } from 'src/utils/authFetch'
import { DistrictType } from 'src/components/_client/create-campaign/Step2-ADformats/outdoor-ad/types'
import { Loader } from 'src/components/_provider/Bookings/content/list/Loader'
import { useAdformatsStore } from 'src/components/_client/create-campaign/Step2-ADformats/adformats-store/adformats.store'

export const MassCoverage = () => {
	const [districts, setDistricts] = useState<DistrictType[]>([])
	const [isLoading, setIsLoading] = useState<boolean>(true)

	const setAdFormatErrors = useAdformatsStore(
		(state) => state.setAdFormatErrors
	)

	const fetchDistricts = async () => {
		setIsLoading(true)
		try {
			const result = await authFetch<Array<DistrictType>>({
				method: 'GET',
				url: '/core/api/v1/campaign/districts',
			})
			if (result) {
				setDistricts(result)
			}
		} catch (e) {
			console.log(e)
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		fetchDistricts()

		return () => {
			setAdFormatErrors('massCoverage', null)
		}
	}, [])

	return (
		<CardWrapper>
			{isLoading ? (
				<Loader />
			) : (
				<OutdoorMassCoverage
					districts={districts}
					setGlobalIsLoading={() => {}}
				/>
			)}
		</CardWrapper>
	)
}
