import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import FieldsForm from './FieldsForm'
import PhoneConfirmation from './PhoneConfirmation'
import useUserData, { PhoneConfirmationVariant } from '../../stores/userData'
import useAuth from './useAuth'
import { ToastsContext } from '../UtilityComponents/ToastsContextProvider'
import { Button } from 'react-bootstrap'
import s from './authstyles/passwordRecovery.module.scss'

export default function RecoveryForm({ cn }) {
	const history = useHistory()
	const [step, setStep] = useState(0)
	const [phone, setPhone] = useState('')
	const [, userInterface] = useUserData()
	const { errors, handleSubmit } = useAuth(async (data) => {
		await userInterface.sendPasswordRecoveryCode(data)
		setPhone(data.phone_number)
		setStep(1)
	})
	const { addToast } = useContext(ToastsContext)

	async function handlePhoneConfirmation(data: {
		code: string
		variant: PhoneConfirmationVariant
	}) {
		await userInterface.acceptPasswordRecoveryCode({
			phone_number: phone,
			code: data.code,
			variant: data.variant,
		})
		setStep(2)
	}

	async function handleCodeResend(variant: PhoneConfirmationVariant) {
		await userInterface.sendPasswordRecoveryCode({
			phone_number: phone,
			variant,
		})
	}

	async function handlePasswordChangeSubmit(values) {
		try {
			await userInterface.restorePassword({ password: values.password })
			addToast({ text: 'Ваш пароль изменен' })
			history.push('/login')
		} catch (e) {
			console.log('-> e', e)
		}
	}

	function handlePasswordValidate(values) {
		const errors: any = {}

		if (!values.password || values.password.length < 8) {
			errors.password = 'Пароль не может быть короче 8 символов'
		}

		if (values.password !== values.newPassword) {
			errors.newPassword = 'Пароли не совпадают'
		}

		return errors
	}

	return (
		<>
			<h1 className={cn('header')}>Сброс пароля</h1>
			{(() => {
				switch (step) {
					case 0:
						return (
							<FieldsForm
								clName={s.authForm}
								extraActionsClassName={
									s.authExtraActionsContainer
								}
								extraActions={
									<AuthActionsWrapper>
										<RecoveryAuthActions cn={cn} />
									</AuthActionsWrapper>
								}
								errors={errors}
								fields={[
									{
										name: 'phone_number',
										autoComplete: 'tel',
										label: 'Телефон',
										placeholder: '+7 (900) 000-00-00',
									},
								]}
								submitLabel={'Продолжить'}
								onSubmit={(values) =>
									handleSubmit({
										...values,
										variant: 'sms',
									})
								}
								cn={cn}
							/>
						)
					case 1:
						return (
							<PhoneConfirmation
								onCodeResend={handleCodeResend}
								onSubmit={handlePhoneConfirmation}
								onBack={() => setStep(0)}
								phone={phone}
								cn={cn}
							/>
						)
					case 2:
						return (
							<>
								<>
									<FieldsForm
										onValidate={handlePasswordValidate}
										fields={[
											{
												name: 'password',
												autoComplete: 'new-password',
												label: 'Новый пароль',
												text: 'Пароль должен содержать не менее восьми знаков, включать буквы, цифры и специальные символы',
											},
											{
												name: 'newPassword',
												type: 'password',
												autoComplete: 'off',
												label: 'Подтверждение пароля',
											},
										]}
										submitLabel={'Обновить пароль'}
										onSubmit={handlePasswordChangeSubmit}
										cn={cn}
									/>
								</>
							</>
						)
					default:
						setStep(0)
						return null
				}
			})()}
		</>
	)
}

type AuthActionsProps = {
	children?: React.ReactNode
}

export const AuthActionsWrapper = ({ children }: AuthActionsProps) => {
	return <div className={s.authActions}>{children}</div>
}

export const RecoveryAuthActions = ({
	cn,
}: {
	cn: (className: string) => string | undefined
}) => {
	const history = useHistory()

	return (
		<>
			<Button
				variant={'link'}
				className={cn('button-link')}
				onClick={() => history.push('/login')}
			>
				Войти
			</Button>
			<Button
				variant={'link'}
				className={cn('button-link')}
				onClick={() => history.push('/register')}
			>
				Создать аккаунт
			</Button>
		</>
	)
}
