import React, { useEffect, useMemo, useState } from 'react'
import useResponsive from 'src/utils/useResponsive'
import { Button, Form, InputGroup } from 'react-bootstrap'
import { TVIcon } from './TVSelector'
import AdMarketMultiplyDropdown from '../../../../UtilityComponents/ADMarketMultiplyDropdown/ADMarketMultiplyDropdown'
import { BASE_URL } from 'src/constants/api'
import { authFetch } from 'src/utils/authFetch'
import useCampaignData from 'src/stores/campaignData'
import useDebounce from 'src/utils/useDebounce'
import { spreadArray } from '../../../../_provider/Mediaformats/MediaInfo'
import InputWithEndText from '../../../../UtilityComponents/InputWithEndText'
import { FormatConfExact } from '../../components'
import { getError } from 'src/utils/getError'
import { CampaignInfoTypeBookingsSeparation } from 'src/stores/ADMarketTypes.types'
import { NumericFormat } from 'react-number-format'
import { useAdformatsStore } from '../adformats-store/adformats.store'

const url_sections = BASE_URL + '/tv_preroll/sections/'
export type ChannelSectionType = {
	id: number
	name: string
	channels_count: number
	channels: {
		id: number
		name: string
	}[]
}

const initialValidate = (campaign: CampaignInfoTypeBookingsSeparation) => {
	return {
		channelsSections: !campaign.adstream.selection_ids.length
			? 'Выберите каналы'
			: '',
		amount:
			campaign.adstream.amount &&
			parseFloat(campaign.adstream.amount) <= 0
				? 'Введите бюджет'
				: '',
	}
}
export const fetchAdstreamInfo = async () =>
	// eslint-disable-next-line compat/compat
	new Promise<{ sections: ChannelSectionType[] }>(async (res, rej) => {
		try {
			const sections: ChannelSectionType[] = await authFetch({
				url: url_sections,
				method: 'GET',
			})
			// eslint-disable-next-line compat/compat
			Promise.all([sections]).then(() => {
				res({ sections })
			})
		} catch (e: any) {
			rej(e)
		}
	})

const adstreamAmountId = 'adstream_amount'
const adstreamChannelsId = 'adstream_channels'

type TVVideoProps = {}

const TVvideo = ({}: TVVideoProps) => {
	const [campaign, campaignInterface] = useCampaignData()

	const [channelsSections, setChannelsSection] = useState<
		ChannelSectionType[]
	>([])

	const setAdFormatErrors = useAdformatsStore(
		(state) => state.setAdFormatErrors
	)
	const addNewAdFormatError = useAdformatsStore(
		(state) => state.addNewAdFormatError
	)

	const [errors, setErrors] = useState(() => initialValidate(campaign))

	const [amount, setAmount] = useState(campaign?.adstream?.amount || '0')
	const [selectedChannelsSectionsIds, setSelectedChannelsSectionsIds] =
		useState<number[]>([])

	const debouncedAmount = useDebounce(amount, 400)
	const debouncedSelectedSectionsIds = useDebounce(
		selectedChannelsSectionsIds,
		700
	) as number[]

	const updateChannelAdv = async (
		body: Partial<{
			selection_ids: number[]
			amount: string
			is_selected: boolean
		}>
	) => {
		try {
			await authFetch({
				url: `/core/api/v1/adv_companies/${campaign.id}/channel-adv/`,
				method: 'PATCH',
				body,
			})
			await campaignInterface.refetchSelected()

			const disableErrors = {
				...errors,
			}
			if (body.selection_ids) {
				disableErrors.channelsSections = ''
			}
			if (body.amount) {
				disableErrors.amount = ''
			}
			setErrors(disableErrors)
		} catch (e) {
			const validateErrors = { ...errors }
			const error = getError(e)
			if (error?.data) {
				if (error.data['selection_ids'])
					validateErrors.channelsSections =
						error.data['selection_ids']?.join(',')
				if (error.data['amount'])
					validateErrors.amount = error.data['amount']?.join(',')
			}
			setErrors(validateErrors)
		}
	}
	const handleSelectedChannelsSectionsChange = async (
		sectionsNames: string[]
	) => {
		if (sectionsNames.length) {
			setErrors({ ...errors, channelsSections: '' })
			const newSelectedSections = channelsSections
				.filter((el) => sectionsNames.includes(el.name))
				.map((el) => el.id)
			setSelectedChannelsSectionsIds(newSelectedSections)
		} else {
			setSelectedChannelsSectionsIds([])
			setErrors({ ...errors, channelsSections: 'Выберите канал' })
		}
	}

	const selectedSectionsNames = useMemo(() => {
		return channelsSections
			.filter((el) => selectedChannelsSectionsIds.includes(el.id))
			.map((el) => el.name)
	}, [selectedChannelsSectionsIds, channelsSections])

	const handleGetFetchSections = async () => {
		const res = await fetchAdstreamInfo()
		setChannelsSection(res.sections)
		const selectedSections = res.sections.filter((el) =>
			campaign.adstream.selection_ids.includes(el.id)
		)
		setSelectedChannelsSectionsIds(selectedSections.map((el) => el.id))
	}

	const handleAmountChange = (e) => {
		const newAmount = e.target.value
			?.replaceAll(' ', '')
			?.replace('₽', '')
			?.replace(',', '.')
		setAmount(newAmount)
	}
	useEffect(() => {
		handleGetFetchSections()
	}, [])

	useEffect(() => {
		if (!!errors.amount) {
			addNewAdFormatError('adstream', {
				field: 'Бюджет',
				id: adstreamAmountId,
				message: errors.amount || 'Проверьте бюждет',
			})
		}
		if (!!errors.channelsSections) {
			addNewAdFormatError('adstream', {
				field: 'Каналы',
				id: adstreamChannelsId,
				message: errors.channelsSections || 'Проверьте каналы',
			})
		}

		return () => {
			setAdFormatErrors('adstream', null)
		}
	}, [errors])
	useEffect(() => {
		if (!!selectedChannelsSectionsIds.length) {
			let isTheSameChannels = false

			if (
				selectedChannelsSectionsIds.length ===
				campaign.adstream.selection_ids.length
			) {
				isTheSameChannels = selectedChannelsSectionsIds.every((id) =>
					campaign.adstream.selection_ids.includes(id)
				)
			}

			if (!isTheSameChannels) {
				const body = {
					selection_ids: selectedChannelsSectionsIds,
				}
				if (campaign.adstream.is_selected) {
					Object.assign(body, { is_selected: false })
				}
				updateChannelAdv(body)
			}
		}
	}, [debouncedSelectedSectionsIds, campaign])
	useEffect(() => {
		if (amount !== campaign.adstream.amount) {
			const body = {
				amount,
			}
			if (campaign.adstream.is_selected) {
				Object.assign(body, { is_selected: false })
			}
			updateChannelAdv(body)
		}
	}, [debouncedAmount])
	const { isMobile } = useResponsive()

	return (
		<Form>
			<div
				className={'d-flex justify-content-between'}
				style={{ marginBottom: '24px' }}
			>
				<h4 className={'mb-0'}>AdStream</h4>
				<div style={isMobile ? { marginLeft: '5px' } : {}}>
					<TVIcon />
				</div>
			</div>

			<ExpandedAdsteamInfo />

			<InputGroup
				id={adstreamChannelsId}
				hasValidation
				style={{ marginBottom: '24px' }}
			>
				<Form.Label>Каналы для показа</Form.Label>
				<AdMarketMultiplyDropdown
					list={channelsSections.map((el) => el.name)}
					error={''}
					selected={selectedSectionsNames}
					onSelectedChange={(sectionsNames) =>
						handleSelectedChannelsSectionsChange(sectionsNames)
					}
					noCheck
					additionalInfo={channelsSections.map((el) => {
						return {
							title: el.name,
							info: GenerateChannelSectionDescription(el),
						}
					})}
					withSeparator
				/>

				<div className="text-danger mt-1" style={{ fontSize: '14px' }}>
					{errors.channelsSections}
				</div>
			</InputGroup>

			<Form.Group className={'mb-3'}>
				<Form.Label>Бюджет</Form.Label>
				<NumericFormat
					id={adstreamAmountId}
					decimalScale={2}
					decimalSeparator={','}
					value={amount}
					suffix=" ₽"
					thousandSeparator={' '}
					customInput={InputWithEndText}
					onChange={handleAmountChange}
					className={`${isMobile ? 'w-100' : 'w-50'}`}
					size={'lg'}
					isInvalid={!!errors.amount}
					FeedBackComponent={
						<Form.Control.Feedback type="invalid">
							{errors.amount}
						</Form.Control.Feedback>
					}
				/>
			</Form.Group>
		</Form>
	)
}

const channelsToDisplay = 5

function GenerateChannelSectionDescription(
	section: ChannelSectionType
): string {
	const { channels, channels_count } = section

	if (channels_count <= channelsToDisplay)
		return `${spreadArray(channels.map((ch) => ch.name))}.`
	return `${spreadArray(
		channels.slice(0, channelsToDisplay).map((ch) => ch.name)
	)} + еще ${channels_count - channelsToDisplay} других.`
}

export default TVvideo

export const ExpandedAdsteamInfo = (props: any) => {
	return (
		<Form.Group className={'mb-4'}>
			<ul className={'d-flex flex-column gap-2'}>
				<li>
					Вам понадобится рекламный видеоролик. Если у вас его нет —
					мы поможем с изготовлением. Соответствующая опция появится
					на следующем этапе «Загрузка материалов».
				</li>
				<li>
					Зрители увидят вашу рекламу при переключении на один из
					каналов выбранной Вами категории. Зрители увидят только один
					Ваш рекламный ролик.
				</li>
				<li>
					Например при бюджете 50 000 ₽ на 2 недели вашу рекламу
					покажут примерно 179 000 раз около 12 000 потенциальных
					клиентов. А при бюджете 100 000 ₽ на 2 недели — около 357
					000 показов рекламы примерно для 25 000 потенциальных
					клиентов.
				</li>
				<li>
					Укажите каналы для показа, бюджет и мы подготовим точный
					расчет вашей рекламной кампании на ТВ.
				</li>
			</ul>
		</Form.Group>
	)
}
