import {
	AdvCategoriesKeys,
	AgeGroupsKeys,
} from 'src/components/_client/create-campaign/constants'
import { MediaType } from '../components/_provider/Mediaformats/MediaInfo'

export type CampaignInfoType = {
	id: string
	state: CampaignStates
	start_date: string
	end_date: string
	name: string
	created_at: string
	is_budget_confirmed: boolean
	is_available_vinstant: boolean
	outdoor_adv_is_selected: boolean
	indoor_adv_is_selected: boolean
	district_adv_is_selected: boolean
	public_transport: null | PublicTransport
	company: CompanyType
	booking: BookingType[]
	districtbookings: DistrictBookingsType[]
	internet_yandex: InternetAdType & { counter_id: string }
	internet_vk: InternetAdType
	media: MediaForBookingType[]
	budget: BudgetType
	budget_vat: BudgetType
	teletarget: TeletargetType
	adstream: AdStreamType
	days_duration: number
	to_payment_waiting_dt: null | string
	active_payments: Payment[]
	active_invoices: Invoice[]
	active_agency_invoices: {
		advertising_campaign_id: string
		amount: string
		comment: string
		created_at: string
		id: number
		status: string
		updated_at: string
	}[]
	sum_payed_payments?: number
	state_data: {}
	regions: TitleAndValue[]
	show_regions: TitleAndValue[]
	genders: TitleAndValue[]
	ages: TitleAndValue[]
	rents: []
	start_time: string
	end_time: string
	bid_requests: Bid_Requests
	most_expensive_city: {
		id: number
		is_selected_visible: boolean
		min_budget: string
		parent_id: number
		title: string
	} | null
	topic: null | AdvCategoriesKeys
	age_marking: AgeGroupsKeys
	auto_posting_legal_info: boolean
	moderation_date: null | string
	confirmed_budget?: number
	confirmed_budget_vat?: number
}

export type Bid_Response = {
	id: number
	request_id: number
	type?: DistrictBookingExternalType
}

export type Bid_Keys =
	| 'booking'
	| 'internet_yandex'
	| 'internet_vk'
	| 'teletarget'
	| 'adstream'
	| 'districtbooking'
	| 'public_transport'

export type Bid_Requests = {
	[k in Bid_Keys]?: Bid_Response[]
}

export type TitleAndValue = { id: number; title: string }

export type CampaignInfoTypeBookingsSeparation = {
	outdoor_bookings: BookingType[]
	indoor_bookings: BookingType[]
} & CampaignInfoType

export enum CampaignStates {
	Draft = 'draft',
	Moderation = 'moderation',
	Payment_waiting = 'payment_waiting',
	Start_waiting = 'start_waiting',
	Active = 'active',
	Completed = 'completed',
	Canceled = 'canceled',
	Confirmed = 'confirmed',
	Paid = 'paid',
}

export type PublicTransport = {
	id: number | null
	state: InternetState
	is_selected: boolean
	views_count: null | number
	image: null | string
	video: null | string
	is_docs_required: boolean
	adjustment_msg: string
	reject_msg: string
	amount: null | string
	fact_cost: null | number
	min_budget: number
}

export type InternetAdType = {
	id: number
	link: null | string
	heading: null | string
	additional_heading: null | string
	text: null | string
	image: null | string
	is_selected: boolean
	views_count: null | number
	state: InternetState
	adjustment_msg: string
	amount: number | null
	button_text?: string
	is_docs_required?: boolean
	min_budget: null | number
	reject_msg: string
}

export type BudgetType = {
	yandex: string
	district: string
	google: string
	internet: string
	banner: string
	channel: string
	tv: string
	booking: string
	indoor: string
	outdoor: string
	transport: string
	total: string
}
export type TeletargetType = {
	id: number
	state: TvState
	is_selected: boolean
	views_count: null | number
	amount: null | string
	image: string | null
	l_image: string | null
	is_small_banner: boolean
	is_l_banner: boolean
	adjustment_msg?: string
	reject_msg?: string
}
export type AdStreamType = {
	id: number
	state: TvState
	is_selected: boolean
	views_count: null | number
	amount: null | string
	cpm: any
	image: string
	frequency: number
	selection_ids: number[]
	geo_list: number[]
	adjustment_msg?: string
	reject_msg?: string
	is_docs_required?: boolean
}
export type PlacementShowingCountType = {
	id: number
	name: string
	price: string
	ac_days_count: number
	ac_price: number
	showing_count_per_day: number
	video_duration: number
}

export type PlacementProvider = {
	id: number
	name: string
	booking_cancel: string
	booking_features: string
}
export type PlacementType = {
	id: number
	provider: PlacementProvider
	placement_type: {
		id: number
		name: string
		image: string
	}
	name: string
	lat: string
	lon: string
	description: null | string
	image: string
	media_type: MediaType
	ots: string
	size: string
	only_work_days: boolean
	works_on_holidays: boolean
	is_active: boolean
	activation_date: string
	placement_showing_counts: PlacementShowingCountType[]
	door_type: 'indoor' | 'outdoor'
	screen_count: number
}

export type BookingType = {
	id: number
	placement_showing_count: PlacementShowingCountType
	state: BookingState
	adjustment_msg: string
	is_active?: boolean
	placement: Omit<PlacementType, 'placement_showing_counts'>
	booking_budget?: number
	available_dates?: { [key: string]: number[] }
	media_creative?: MediaForBookingType
	is_docs_required?: boolean
}

export type DistrictBookingExternalType =
	| 'ss'
	| 'bb'
	| 'rb'
	| 'cf'
	| 'rc'
	| 'wd'
	| 'rd'
export type DistrictBookingsCreativeType = {
	id: string
	file: string
	file_name: string
	external_type: DistrictBookingExternalType
	edit_vinstant: null | string
	recommendations: string[]
}

export type DistrictBookingsType = {
	id: number
	state: BookingState
	adjustment_msg: string
	amount: string
	city_name: string
	city_id: number
	creatives: Array<DistrictBookingsCreativeType>
	center_lat: string
	center_lon: string
	radius: string
	count_placements: number
	placements: PlacementType[]
	rec_budget: null | number
	reject_msg: string
	is_docs_required: boolean
}

export enum BookingState {
	Draft = 'draft',
	Inner_Moderation = 'inner_moderation',
	Moderation = 'moderation',
	Adjustment = 'adjustment',
	Confirm = 'confirm',
	Completed = 'completed',
	Reject = 'reject',
	Canceled = 'canceled',
	Inner_Confirm = 'inner_confirm',
}
export enum InternetState {
	Draft = 'draft',
	Inner_Moderation = 'inner_moderation',
	Moderation = 'moderation',
	Adjustment = 'adjustment',
	Confirm = 'confirm',
	Completed = 'completed',
	Reject = 'reject',
	Canceled = 'canceled',
	Inner_Confirm = 'inner_confirm',
}
export enum TvState {
	Draft = 'draft',
	Inner_Moderation = 'inner_moderation',
	Moderation = 'moderation',
	Adjustment = 'adjustment',
	Confirm = 'confirm',
	Completed = 'completed',
	Reject = 'reject',
	Canceled = 'canceled',
	Inner_Confirm = 'inner_confirm',
}

export type MediaForBookingType = {
	id: number
	file: string
	file_name: string
	booking_id: number
	is_self_downloaded: boolean
}

export type CompanyType = {
	id: string
	name: string
	industry_type: {
		id: number
		name: string
		price: string
		min_shows_count: number
		max_shows_count: number
	}
	inn: string
	kpp: string
	legal_name: string
	actual_location: string
	actual_location_lat: string
	actual_location_lon: string
	legal_short_name: string
	counts: {
		draft: number
		canceled: number
		moderation: number
		payment_waiting: number
		active: number
		completed: number
	}
	is_selfbusy: boolean
	is_draft?: boolean
	is_need_send_edo_document?: boolean
}

export type PlacementShort = {
	pk: number
	image_type: 'billboard' | 'cityformat'
	is_active: boolean
	lat: string
	lon: string
	placement_type_name: string
	size: string
	name: string
	door_type: 'outdoor' | 'indoor'
	image_url: string | null
}

export type Payment = {
	id: string
	amount: number
	payment_url: string
	refunded_amount: null | number
	status: PaymentStatus
	created_at: string
}
enum PaymentStatus {
	NEW = 'new',
}
export interface Operations {
	id: number
	account_number: string
	campaign_name: string
	external_id: string
	operation_id: string
	document_date: Date
	amount: number
	payer_name: string
	payer_inn: string
	payer_kpp: string
	payer_bic: string
	payment_purpose: string
	payment: Payment
	invoice: Invoice
}

export interface Invoice {
	id: number
	advertising_campaign_id: string
	amount: string
	invoice_number: string
	external_url: string
	status: string
	due_date: string
	created_at: string
}

type ForeCastFields = {
	click_count: null | number
	forecast_views_count: null | number
}

export enum ReleatedKey {
	VK = 'vkinternetadvertising',
	Yandex = 'yandexinternetadvertising',
	Teletarget = 'teletargetadversting',
	Adstream = 'adstreamadversting',
}

export interface Statistics {
	booking: (BookingType & ForeCastFields)[]
	id: string
	start_date: string
	end_date: string
	booking_id: [number]
	releated_key: ReleatedKey
	placement_id: [number]
	name: string
}

export interface ForeCast {
	adstream: AdStreamType & ForeCastFields
	booking: (BookingType & ForeCastFields)[]
	district_booking: (DistrictBookingsType & ForeCastFields)[]
	created_at: string
	end_date: string
	end_time: string
	id: string
	indoor_adv_is_selected: boolean
	is_budget_confirmed: boolean
	name: string
	outdoor_adv_is_selected: boolean
	start_date: string
	start_time: string
	state: CampaignStates
	teletarget: TeletargetType & ForeCastFields
	vk: InternetAdType & ForeCastFields
	yandex: InternetAdType & ForeCastFields
	public_transport: PublicTransport & ForeCastFields
	district_adv_is_selected: boolean
}
