import cn from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button } from 'react-bootstrap'
import { getPlacements } from '../../../../../constants/api'
import {
	PlacementShort,
	PlacementShowingCountType,
	PlacementType,
} from '../../../../../stores/ADMarketTypes.types'
import useCampaignData from '../../../../../stores/campaignData'
import { authFetch } from '../../../../../utils/authFetch'
import useResponsive from '../../../../../utils/useResponsive'
import ModalYandexMap from '../../../../UtilityComponents/ModalYandexMap'
import YandexMap from '../../../../UtilityComponents/YandexMap'
import { AdIcon } from '../AdFormatSelector'
import s from './BookingAD.module.scss'
import PlacementCard from './PlacementCard'
import PlacementPickerModal from './PlacementPicker/PlacementPickerModal'
import IntlFormatter from 'src/utils/intl-money-formatter'
import { formattedNumber } from 'src/utils'

export type PlacementWithOnlyOneShowingCount = Omit<
	PlacementType,
	'placement_showing_counts'
> & {
	placement_showing_count: PlacementShowingCountType
}

export interface PlacementShortWithStateAndFocus extends PlacementShort {
	name: string
	focus: null | 'focus'
	state: 'selected' | 'default' | 'unactive'
	placement_detail?: PlacementWithOnlyOneShowingCount | undefined
}

type BookingsType = 'indoor' | 'outdoor'

function getLocation(
	successCallback: (position: GeolocationPosition) => void,
	errorCallback: () => void,
	defaultCallback?: () => void
) {
	// Если геолокация поддерживается браузером
	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(successCallback, errorCallback)
	} else {
		console.warn('Geolocation is not supported by this browser.')
		defaultCallback && defaultCallback()
	}
}
const MoscowCenter = [55.75222, 37.61556]

const getPlacementsWithState = (data: {
	placements: PlacementShort[]
	usedPlacementsIds: number[]
}) => {
	const placementsWithState: PlacementShortWithStateAndFocus[] =
		data.placements.map((el) => {
			if (data.usedPlacementsIds.includes(el.pk)) {
				return {
					...el,
					state: 'selected',
					focus: null,
				}
			} else {
				if (el.is_active) {
					return {
						...el,
						state: 'default',
						focus: null,
					}
				} else {
					return {
						...el,
						state: 'unactive',
						focus: null,
					}
				}
			}
		})
	return placementsWithState
}
const BookingAD: React.FC<{
	isHiddenContent: boolean
	bookings_type: BookingsType
	onError: Function
	setIsLoading: Function
	isHeader?: boolean
}> = ({ bookings_type, setIsLoading, isHeader = true }) => {
	const [campaign, campaignInterface] = useCampaignData()
	const [locationPickerOpen, setLocationPickerOpen] = useState(false)
	const [placements, setPlacements] = useState<PlacementShort[]>([])

	const [buisnessCoords, setBuisnessCoords] = useState([
		+campaign?.company?.actual_location_lat,
		+campaign?.company?.actual_location_lon,
	])

	const { isMobile } = useResponsive()
	const [modalMapOpen, setModalMapOpen] = useState(false)
	const [loading, setLoading] = useState(false)
	const title =
		bookings_type === 'indoor' ? 'Внутренняя реклама' : 'Наружная реклама'

	const resetVariantIsSelectedState = useCallback(async () => {
		const variantKey =
			bookings_type === 'indoor'
				? 'indoor_adv_is_selected'
				: 'outdoor_adv_is_selected'

		await campaignInterface.patchCampaign(campaign.id, {
			[variantKey]: false,
		})
	}, [bookings_type])

	async function handleBookingUpdate(
		bookings: { placement_showing_count_id: number }[]
	) {
		//add other bookings to update array
		setLoading(true)
		let other: { placement_showing_count_id: number }[] = []
		if (bookings_type === 'indoor')
			campaign.outdoor_bookings.forEach((booking) =>
				other.push({
					placement_showing_count_id:
						booking.placement_showing_count.id,
				})
			)
		if (bookings_type === 'outdoor')
			campaign.indoor_bookings.forEach((booking) =>
				other.push({
					placement_showing_count_id:
						booking.placement_showing_count.id,
				})
			)

		await campaignInterface.updateBooking(
			campaign.id,
			[...other, ...bookings],
			false
		)
		await resetVariantIsSelectedState()
		await campaignInterface.refetchSelected()
		setLoading(false)
	}

	async function handleDeleteBooking(id) {
		setLoading(true)
		setIsLoading(true)

		await campaignInterface.deleteBooking(id, false)
		await resetVariantIsSelectedState()
		await campaignInterface.refetchSelected()
		setIsLoading(false)
		setLoading(false)
	}
	const typedBookings = useMemo(() => {
		switch (bookings_type) {
			case 'indoor':
				return campaign.indoor_bookings
			case 'outdoor':
				return campaign.outdoor_bookings
			default:
				return campaign.booking
		}
	}, [campaign, bookings_type])

	const handleDeleteAllBookings = async (
		booking_type: typeof bookings_type
	) => {
		setLoading(true)
		setIsLoading(true)
		await campaignInterface.deleteAllBookingsByType(bookings_type, false)
		await resetVariantIsSelectedState()
		await campaignInterface.refetchSelected()
		setLoading(false)
		setIsLoading(false)
	}

	const totals = useMemo(() => {
		const totals = { shows: 0, price: 0 }
		for (const booking of typedBookings) {
			totals.shows +=
				booking.placement_showing_count.showing_count_per_day
			totals.price += booking.placement_showing_count.ac_price
		}
		return totals
	}, [typedBookings])

	const handleAccessGeoLocation = async (position: GeolocationPosition) => {
		await setBuisnessCoords([
			position.coords.latitude,
			position.coords.longitude,
		])
		await setLocationPickerOpen(true)
	}
	const handleRejectGeoLocation = async () => {
		await setBuisnessCoords(MoscowCenter)
		await setLocationPickerOpen(true)
	}
	const handleModalOpen = () => {
		if (
			!campaign?.company?.actual_location_lat ||
			!campaign?.company?.actual_location_lon
		) {
			getLocation(handleAccessGeoLocation, handleRejectGeoLocation, () =>
				setBuisnessCoords(MoscowCenter)
			)
		} else {
			setLocationPickerOpen(true)
		}
	}

	const placementsWithState = useMemo(() => {
		return getPlacementsWithState({
			placements,
			usedPlacementsIds: typedBookings.map((el) => el.placement.id),
		})
	}, [typedBookings, placements])

	useEffect(() => {
		;(async () => {
			setLoading(true)
			let res: PlacementShort[] = await authFetch({
				url: getPlacements.params(bookings_type),
				method: 'GET',
			})
			setPlacements(res)
			setLoading(false)
		})()
	}, [])
	return (
		<>
			{isHeader && (
				<>
					<div
						className={'d-flex justify-content-between'}
						style={{ marginBottom: '8px' }}
					>
						<h4 className={'mb-0'}>{title}</h4>
						<div>
							<AdIcon name={bookings_type} />
						</div>
					</div>
					<div className={'mb-3'}>
						{bookings_type === 'indoor'
							? 'Добавьте рекламные места'
							: 'Бронируются конкретные экраны с гарантированным числом показов в сутки на весь период кампании. Цена фиксируется на момент подтверждения кампании.'}
					</div>
				</>
			)}

			{typedBookings.length !== 0 && (
				<div
					className={'d-flex flex-nowrap'}
					style={{
						flexDirection: isMobile ? 'column' : 'row',
					}}
				>
					<div className={'flex-grow-1 mb-4 position-relative'}>
						<YandexMap
							width={'100%'}
							height="250px"
							area={false}
							zoom={12}
							fixed={true}
							buisnessCoords={buisnessCoords}
							smallData={null}
							onClick={() => setModalMapOpen(true)}
						/>
						<div
							style={{
								position: 'absolute',
								top: 'calc(100% - 60px)',
								right: isMobile ? 'calc(50% - 75px)' : '20px',
							}}
						>
							<Button
								variant={'secondary'}
								size={'sm'}
								onClick={() => setModalMapOpen(true)}
							>
								Смотреть на карте
							</Button>
						</div>
					</div>
				</div>
			)}

			{typedBookings.map((data, i) => (
				<React.Fragment key={i}>
					<PlacementCard
						data={data}
						onDelete={handleDeleteBooking}
						isMobile={isMobile}
						key={data.id}
					/>
				</React.Fragment>
			))}
			{typedBookings.length !== 0 && (
				<div style={{ marginBottom: '8px' }} />
			)}
			{isMobile ? (
				<>
					{!!typedBookings.length && (
						<div className="d-flex">
							<Button
								className="flex-grow-1 "
								variant={'danger'}
								onClick={() =>
									handleDeleteAllBookings(bookings_type)
								}
								disabled={loading}
							>
								Удалить все
							</Button>
						</div>
					)}
					<div className="d-flex">
						<Button
							className="flex-grow-1 mt-2"
							variant={'primary'}
							onClick={handleModalOpen}
							disabled={loading}
						>
							<i className="bi bi-plus-lg me-2" />
							Добавить места
						</Button>
					</div>
				</>
			) : (
				<div className="d-flex">
					<Button
						className="flex-grow-1"
						variant={'primary'}
						onClick={handleModalOpen}
						disabled={loading}
					>
						<i className="bi bi-plus-lg me-2" />
						Добавить места
					</Button>
					{!!typedBookings.length && (
						<Button
							className="flex-grow-0 ms-3"
							variant={'danger'}
							onClick={() =>
								handleDeleteAllBookings(bookings_type)
							}
							disabled={loading}
						>
							Удалить все
						</Button>
					)}
				</div>
			)}
			{typedBookings.length !== 0 && (
				<div className={s.totals}>
					<div className={cn(s.totalsItem, s.bb)}>
						<div>Всего рекламных мест:</div>
						<div>{typedBookings.length}</div>
					</div>
					<div className={cn(s.totalsItem, s.bb)}>
						<div>Всего рекламу увидят:</div>
						<div>~ {formattedNumber(totals.shows)} чел./день</div>
					</div>
					<div className={s.totalsItem}>
						<div>Стоимость всего:</div>
						<div>{IntlFormatter.format(totals.price)} </div>
					</div>
				</div>
			)}
			{locationPickerOpen && (
				<PlacementPickerModal
					buisnessCoords={buisnessCoords}
					show={locationPickerOpen}
					onHide={() => setLocationPickerOpen(false)}
					placements={placementsWithState}
					handleUpdateBookings={handleBookingUpdate}
					bookings_type={bookings_type}
				/>
			)}
			{modalMapOpen && (
				<ModalYandexMap
					show={modalMapOpen}
					onHide={() => setModalMapOpen(false)}
					modalTitle={`Выбранные места ${
						bookings_type === 'indoor' ? 'внутренней' : 'наружной'
					} рекламы`}
					fullScreen={true}
					yMapProps={{
						width: '100%',
						height: '100%',
						area: true,
						zoom: 12,
						withZoomControls: true,
						isMobile: isMobile,
						buisnessCoords: buisnessCoords,
						smallData:
							placementsWithState.length === 0
								? null
								: placementsWithState.filter(
										(el) => el.state === 'selected'
								  ),
					}}
				/>
			)}
		</>
	)
}

export default BookingAD
