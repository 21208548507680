import React, { useEffect, useState } from 'react'
import s from '../../style.module.scss'
import cn from 'classnames'
import moment from 'moment'
import {
	advItemStatus,
	campaignStatus,
} from 'src/components/_moderator/constants'
import useModerationData from 'src/components/_moderator/store/moderationStore'
import { DistrictBookingsType } from 'src/stores/ADMarketTypes.types'
import { authFetch } from 'src/utils/authFetch'
import ImageFullScreenWrapper from 'src/components/UtilityComponents/ImageFullScreenWrapper'
import IntlFormatter from 'src/utils/intl-money-formatter'
import { CampaignItemInfoField } from './CampaignItemInfoField'
import { ModerationModalButton } from './ModerationModalButton'
import { districtBookingExternalTitles } from 'src/components/_client/create-campaign/Step3-Upload/constants/districtBookingExternalTitles'
import { ExtraFields } from 'src/components/_moderator/types/ModerationRequestTypes'
import { DistrictType } from 'src/components/_client/create-campaign/Step2-ADformats/outdoor-ad/types'

type ModalItemInfoProps = {
	src: string
	state: any
	budget: string | number
	format: string
	visibility: string
	viewsCount: number
	clicksCount: number
	expenses: number
	onChange?: () => {}
	name?: string
	address?: string
	onlyWorksDay?: boolean
	setAdvItemActionType: (
		action: 'reject' | 'adjustment' | 'moderation'
	) => void
}
export const ModalItemInfo = ({
	budget,
	src,
	state,
	visibility,
	clicksCount,
	expenses,
	format,
	viewsCount,
	address,
	name,
	onlyWorksDay,
	setAdvItemActionType,
}: ModalItemInfoProps) => {
	const [moder] = useModerationData()

	const [image, setImage] = useState(src)

	const date = `с ${moment(moder.detailedCampaign.start_date).format(
		'DD MMMM '
	)} по ${moment(moder.detailedCampaign.end_date).format('DD MMMM YYYY')}`

	const infoFields = {
		['Срок размещения']: date,
		['График показов']: !!onlyWorksDay
			? 'Только рабочие дни'
			: 'Непрерывно',
		['Рабочих дней']: moder.detailedCampaign.days_duration,
		['Бюджет']: IntlFormatter.format(budget),
		['Формат']: format,
		['Рекламу увидят']: `${visibility} чел./день`,
		['Расход']: expenses,
		['Клики']: clicksCount,
		['Показы']: viewsCount,
	}

	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<ImageFullScreenWrapper fullWidth>
				<img
					onError={() =>
						setImage(
							'https://storage.yandexcloud.net/admarket-dev-data/import/vdooh/default.jpg'
						)
					}
					src={image}
					alt="media"
					style={{
						width: '100%',
						height: '320px',
					}}
				/>
			</ImageFullScreenWrapper>

			<div
				style={{
					width: '100%',
					padding: '24px 40px',
					display: 'flex',
					flexDirection: 'column',
					gap: '24px',
				}}
			>
				<div className={s.detailModalHeader}>
					<h4>{name}</h4>
					<h4>{address}</h4>
				</div>

				<div>
					<CampaignItemInfoField
						title="Статус"
						mainStyle={{ paddingTop: 0 }}
					>
						<div
							style={{
								height: 'min-content',
								borderRadius: '4px',
								padding: '3px 4px',
								color: 'white',
								backgroundColor:
									campaignStatus?.[state]?.color || 'gray',
							}}
						>
							<p className={cn(s.campaignItemStatus)}>
								{advItemStatus?.[state]?.title || state}
							</p>
						</div>
					</CampaignItemInfoField>
					{infoFields &&
						Object.keys(infoFields!).map((key, index) => (
							<CampaignItemInfoField
								key={key}
								title={key}
								text={infoFields[key]}
								mainStyle={{
									borderBottom:
										index ===
										Object.keys(infoFields).length - 1
											? 'none'
											: '',
								}}
								textStyle={{
									color:
										parseFloat(infoFields[key]) === 0
											? '#CED4DA'
											: '',
								}}
							/>
						))}
				</div>

				<div style={{ display: 'flex', width: '100%', gap: '16px' }}>
					<ModerationModalButton
						isLoading={moder.isModerLoading}
						setAdvItemActionType={setAdvItemActionType}
						state={state}
					/>
				</div>
			</div>
		</div>
	)
}
export const ModalItemInfoDistrictBooking = ({
	districtbooking,
	setAdvItemActionType,
}: {
	districtbooking: DistrictBookingsType & Omit<ExtraFields, 'edit_vinstant'>
	setAdvItemActionType: (
		action: 'reject' | 'adjustment' | 'moderation'
	) => void
}) => {
	const [moder] = useModerationData()

	const [districts, setDistricts] = useState<DistrictType[]>([])
	const date = `с ${moment(moder.detailedCampaign.start_date).format(
		'DD MMMM YYYY'
	)} по ${moment(moder.detailedCampaign.end_date).format('DD MMMM YYYY')}`

	const getAllUsedCreativeTypeTitles = () => {
		return districtbooking.creatives
			.map(
				(creative) =>
					districtBookingExternalTitles[creative.external_type]
			)
			.join(', ')
	}

	const infoFields = {
		['Срок размещения']: date,
		// ['График показов']: !!onlyWorksDay
		// 	? 'Только рабочие дни'
		// 	: 'Непрерывно',
		['Рабочих дней']: moder.detailedCampaign.days_duration,
		['Бюджет']: IntlFormatter.format(districtbooking.amount),
		['Формат']: 'Видео',
		['Выбранные форматы']: getAllUsedCreativeTypeTitles(),
		// ['Рекламу увидят']: `${parseFloat(0)} чел./день`,
		['Расход']: districtbooking.expense,
		['Клики']: districtbooking.click_count,
		['Показы']: districtbooking.views_count,
	}

	const fetchDistricts = async () => {
		const result = await authFetch<Array<DistrictType>>({
			method: 'GET',
			url: '/core/api/v1/campaign/districts',
		})
		if (result) {
			setDistricts(result)
		}
	}

	useEffect(() => {
		fetchDistricts()
	}, [])
	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<ImageFullScreenWrapper fullWidth>
				<img
					onError={() => {}}
					src={
						'https://storage.yandexcloud.net/admarket-images/static-img/outdoor_26_11zon.jpg'
					}
					alt="media"
					style={{
						width: '100%',
						height: '320px',
					}}
				/>
			</ImageFullScreenWrapper>

			<div
				style={{
					width: '100%',
					padding: '24px 40px',
					display: 'flex',
					flexDirection: 'column',
					gap: '24px',
				}}
			>
				<div className={s.detailModalHeader}>
					<h4>
						{
							districts.find(
								(el) => el.id === districtbooking.city_id
							)?.title
						}
					</h4>
				</div>

				<div>
					<CampaignItemInfoField
						title="Статус"
						mainStyle={{ paddingTop: 0 }}
					>
						<div
							style={{
								height: 'min-content',
								borderRadius: '4px',
								padding: '3px 4px',
								color: 'white',
								backgroundColor:
									campaignStatus?.[districtbooking.state]
										?.color || 'gray',
							}}
						>
							<p className={cn(s.campaignItemStatus)}>
								{advItemStatus?.[districtbooking.state]
									?.title || districtbooking.state}
							</p>
						</div>
					</CampaignItemInfoField>
					{infoFields &&
						Object.keys(infoFields!).map((key, index) => (
							<CampaignItemInfoField
								key={key}
								title={key}
								text={infoFields[key]}
								mainStyle={{
									borderBottom:
										index ===
										Object.keys(infoFields).length - 1
											? 'none'
											: '',
								}}
								textStyle={{
									color:
										parseFloat(infoFields[key]) === 0
											? '#CED4DA'
											: '',
								}}
							/>
						))}
				</div>

				<div style={{ display: 'flex', width: '100%', gap: '16px' }}>
					<ModerationModalButton
						setAdvItemActionType={setAdvItemActionType}
						state={districtbooking.state as any}
					/>
				</div>
			</div>
		</div>
	)
}
